.realizations {
	padding: 50px 0;
	margin-top: 100px;
	background-color: #f4f4f4;
	&--no-margin {
		margin-top: 0;
	}
	&__container {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
	}
	&__subtitle,
	&__title {
		font-weight: 900;
		text-transform: uppercase;
		text-align: center;
	}
	&__subtitle {
		font-size: 32px;
		color: $secondary;
		display: block;
	}
	&__title {
		color: $secondary;
		font-size: 50px;
	}
	&__text {
		font-size: 1.8rem;
		padding-top: 25px;
		text-align: center;
		line-height: 35px;
		margin-bottom: 35px;
	}
	&__content {
		width: 100%;
	}
	&__showcase {
		width: 100%;
	}
	&__list {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-top: 50px;
	}
	&__item {
		flex-shrink: 0;
		border-radius: 15px;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin-bottom: 80px;
		@include mq(md) {
			margin: 10px 10px 60px 10px;
			width: calc(50% - 20px);
		}
		@include mq(lg) {
			width: calc(33% - 20px);
		}
		&-image {
			position: relative;
			background-size: cover;
			background-position: center;
			min-height: 200px;
			width: 80%;
			top: -50px;
			border-radius: 10px;
			box-shadow: $shadowBtn;
		}
		&-inner {
			position: relative;
			top: -30px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		&-title {
			font-size: 2rem;
		}
		&-btn {
			margin-top: 30px;
		}
	}
	&__grid {
		margin-top: 50px;
	}
	&__gridSingle {
		overflow: hidden;
		box-shadow: $shadowBtn;
		border-radius: 5px;
		display: flex;
		img {
			width: 100%;
			transition: 0.3s ease;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
}
