.content {
	background-color: #f4f4f4;
	padding: 25px 0 50px;
	position: relative;
	z-index: 2;
	.rank-math-breadcrumb {
		position: relative;
		z-index: 2;
		color: #fff;
		a {
			color: #fff;
			text-decoration: none;
			transition: color 0.3s ease;
			&:hover {
				color: $primary;
			}
		}
	}
	&__header {
		box-shadow: $shadowBtn;
		height: 350px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		position: relative;
		overflow: hidden;
		padding: 50px;
		background-size: cover;
		background-position: center;
		border-radius: 15px;
		@include mq(md) {
			padding: 100px 0;
		}
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			// background: $primary;
			background: #000;
			opacity: 0.4;
		}
	}
	&__title {
		font-size: 2.5rem;
		text-transform: uppercase;
		color: $secondary;
		font-weight: 900;
		text-align: center;
		color: #fff;
		width: 100%;
		position: relative;
		z-index: 2;
		margin: 0;
		@include mq(md) {
			font-size: 5rem;
		}
	}
	&__main {
		padding: 25px 0;
	}
	p {
		font-size: 1.6rem;
		line-height: 30px;
		padding: 15px 0;
		text-align: justify;
	}
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: 400;
	}
	h2 {
		font-size: 2.7rem;
		margin-bottom: 10px;
	}
	h3 {
		font-size: 2.2rem;
		margin-bottom: 10px;
	}
	h4 {
		font-size: 1.8rem;
		margin-bottom: 10px;
	}
	ul,
	ol {
		padding: 10px 15px;
		li {
			padding: 10px 0;
			font-size: 1.6rem;
		}
	}
}
