.form {
	padding: 25px;
	background-color: #fff;
	box-shadow: $shadow;
	border-radius: 15px;
	&__btn-container {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}
	.ajax-loader {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}
	.material {
		position: relative;
		margin: 35px 0 10px;
		label {
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
			background-color: #fff;
			color: $secondary;
			font-size: 1.4rem;
			padding: 0 2px;
			z-index: 2;
			transition: 0.2s ease;
			transform-origin: left;
			&.is-focused {
				top: 0;
				transform: translateY(-50%) scale(0.8);
				z-index: 5;
				color: $primary;
			}
		}
		input,
		textarea {
			padding: 15px 20px;
			background: transparent;
			border: none;
			border-bottom: 1px solid #f4f4f4;
			width: 100%;
			outline: transparent;
			font-size: 1.4rem;
			&.is-focused {
				border-bottom: 1px solid $primary;
			}
		}
		.wpcf7-form-control-wrap {
			display: block;
			span {
				position: absolute;
				bottom: -15px;
				left: 0;
			}
		}
	}
	input[type="submit"] {
		@extend .button;
		border: none;
		cursor: pointer;
		width: 100%;
	}
}
