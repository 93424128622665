.button {
	text-align: center;
	padding: 1.2rem 3.1rem;
	background: $gradient;
	box-shadow: $shadowBtn;
	color: #fff;
	font-size: 1.6rem;
	border-radius: 20px;
	text-decoration: none;
	display: inline-block;
	transition: 0.3s ease;
	background-size: 200%;
	&:hover {
		background-position: right center;
		transform: scale(1.03);
	}
}
