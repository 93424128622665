.cta {
	padding: 100px 0;
	// background: $primary;
	position: relative;
	background-size: cover;
	background-position: center;
	position: relative;
	&__image {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0.6;
		background-color: $dark;
	}
	&__container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 2;
	}
	&__title {
		text-align: center;
		text-transform: uppercase;
		font-weight: 900;
		font-size: 2.5rem;
		color: #fff;
		@include mq(md) {
			font-size: 4rem;
		}
	}
	&__text {
		font-size: 1.8rem;
		color: #fff;
		margin-top: 20px;
		text-align: center;
	}
	&__button {
		margin-top: 30px;
		font-weight: 400;
		padding: 12px 50px;
		font-size: 1.8rem;
		text-transform: uppercase;
	}
}
