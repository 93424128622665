.footer {
	padding: 25px 0;
	&__container {
		display: flex;
		flex-direction: column;
		@include mq(md) {
			flex-direction: row;
			justify-content: space-between;
		}
	}

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		@include mq(md) {
			align-items: flex-start;
			justify-content: flex-start;
		}
	}
	&__column {
		margin: 20px 0;
		@include mq(md) {
			margin: 0;
		}
	}
	&__contact {
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		color: $secondary;
		font-size: 1.6rem;
		font-weight: bold;
		margin: 10px 0;
		line-height: 25px;
		@include mq(md) {
			justify-content: flex-start;
		}
		img {
			height: auto;
			margin-right: 10px;
		}
	}
	&__title {
		font-weight: bold;
		color: $secondary;
		font-size: 2rem;
		text-align: center;
		@include mq(md) {
			text-align: left;
		}
	}
}
