.contact {
	padding: 50px 0;
	&__container {
		display: flex;
		flex-direction: column;
		@include mq(lg) {
			flex-direction: row;
		}
	}
	&__details {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		width: 100%;
		@include mq(lg) {
			width: 50%;
		}
	}
	&__form {
		width: 100%;
		@include mq(lg) {
			width: 50%;
		}
	}
	&__title {
		font-size: 4rem;
		font-weight: 900;
		text-transform: uppercase;
	}
	&__text {
		font-size: 1.8rem;
		margin-top: 10px;
		line-height: 35px;
		@include mq(lg) {
			padding: 10px 70px 10px 0;
		}
	}
	&__data {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		margin-top: 30px;
		a {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			font-size: 1.6rem;
			margin: 15px 0;
			font-weight: bold;
			color: $secondary;
			text-decoration: none;
			img {
				margin-right: 10px;
			}
		}
	}
}
