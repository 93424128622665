@mixin mq($breakpoint) {
	$breakpoint: map-get($breakpoints, $breakpoint);
	@if ($breakpoint) {
		@media (min-width: #{$breakpoint}) {
			@content;
		}
	}
}

@mixin flex($positionX: center, $positionY: center) {
	display: flex;
	justify-content: $positionX;
	align-items: $positionY;
}
@mixin flexContainer($isCenter: true) {
	display: flex;
	flex-direction: column;
	@if $isCenter {
		justify-content: center;
	}
	@include mq(md) {
		flex-direction: row;
		@if $isCenter {
			align-items: center;
		}
	}
}
