.offer {
	&__subtitle,
	&__title {
		font-weight: 900;
		text-align: center;
		text-transform: uppercase;
	}
	&__subtitle {
		font-size: 32px;
		color: $secondary;
		display: block;
	}
	&__title {
		color: $secondary;
		font-size: 50px;
	}
	&__list {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		margin-top: 30px;
		@include mq(md) {
			flex-direction: row;
		}
	}
	&__item {
		padding: 20px;
		margin: 10px;
		box-shadow: $shadow;
		border-radius: 15px;
		position: relative;
		width: 100%;
		overflow: hidden;
		background-color: #fff;
		max-width: calc(100% - 20px);
		@include mq(md) {
			max-width: calc(50% - 20px);
		}
		@include mq(lg) {
			max-width: calc(33% - 20px);
		}
		& > * {
			position: relative;
			z-index: 4;
		}
		.offer__item-title,
		.offer__item-desc,
		.offer__item-btn {
			transition: 0.3s ease;
			transition-delay: 0.1s;
		}
		&::before {
			content: "";
			clip-path: circle(0vh at 0% 0%);
			background: $gradientTwo;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: 3;
			bottom: 0;
			transition: 0.4s ease;
		}
		&.hovered {
			&::before {
				clip-path: circle(150% at 0% 0%);
			}
			.offer__item-title,
			.offer__item-desc,
			.offer__item-btn {
				color: #fff;
			}
		}

		&-link {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			text-decoration: none;
			margin-bottom: 20px;
		}
		&-image {
			width: 100px;
			height: 100px;
			object-fit: cover;
			margin-right: 20px;
			border-radius: 100%;
			box-shadow: $shadowBtn;
		}
		&-title {
			font-size: 2.2rem;
			color: $secondary;
			font-weight: 500;
			&::after {
				content: "";
				width: 50%;
				display: block;
				height: 3px;
				background-color: $primary;
				margin-top: 10px;
			}
		}
		&-desc {
			font-size: 1.6rem;
			color: #000;
			line-height: 25px;
			text-align: justify;
		}
		&-btn {
			font-size: 1.8rem;
			color: $primary;
			text-transform: uppercase;
			text-decoration: none;
			font-weight: bold;
			display: inline-block;
			margin-top: 15px;
			margin-bottom: 10px;
		}
	}
}
