.counters {
	background-color: $primary;
	padding: 10rem 0;
	position: relative;
	margin-bottom: 3rem;
	&__subtitle,
	&__title {
		font-weight: 900;
		text-align: center;
		text-transform: uppercase;
	}
	&__subtitle {
		font-size: 3.2rem;
		display: block;
		color: #fff;
	}
	&__title {
		color: #fff;
		font-size: 5rem;
	}
	&__wrapper {
		display: flex;
		margin-top: 40px;
		flex-direction: column;
		align-items: center;
		@include mq(md) {
			flex-direction: row;
		}
	}
	&__counter {
		max-width: calc(33% - 20px);
		width: 100%;
		margin: 30px 0;
		font-weight: 400;
		font-size: 1.8rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		@include mq(md) {
			margin: 10px;
		}
		&-number {
			font-size: 5rem;
			font-weight: 900;
			color: #fff;
			margin-bottom: 10px;
		}
		&-text {
			color: #fff;
		}
	}
	&__text {
		text-align: center;
		font-size: 1.8rem;
		margin: 30px 0;
		color: #fff;
		line-height: 35px;
	}
	#percentege {
		&::after {
			content: "%";
		}
	}
}
