.gallery {
	background-color: #f4f4f4;
	padding: 25px 0 50px;
	&__title {
		font-size: 4rem;
		font-weight: 900;
		color: $secondary;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 20px;
	}
	&__list {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		width: calc(100% - 10px);
		margin: 5px;
		@include mq(md) {
			width: calc(33% - 10px);
		}
		@include mq(lg) {
			width: calc(25% - 10px);
		}
	}
	&__image {
		overflow: hidden;
		display: block;
		&:hover {
			img {
				transform: scale(1.1);
			}
		}
		img {
			width: 100%;
			transition: 0.3s ease;
			@include mq(md) {
				height: 250px;
				width: auto;
			}
		}
	}
}
